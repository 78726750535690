.compass-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.compass {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 2px solid black;
  position: relative;
}

.direction {
  position: absolute;
  font-size: 20px;
  font-weight: bold;
}

.north {
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.east {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.south {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.west {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.needle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 50%;
  background-color: red;
  transform-origin: bottom;
}
